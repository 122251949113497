import useSWR from "swr";
import get from "lodash/get";
import map from "lodash/map";
import filter from "lodash/filter";
import startsWith from "lodash/startsWith";
import isEmpty from "lodash/isEmpty";
import Link from "next/link";

import styles from "./Menu.module.scss";

import Image from "grandus-lib/components-atomic/image/Image";

import { CATEGORY_MICE, HEADER_MENU_CATGEORY } from "constants/CustomConstants";
import { useRouter } from "next/router";
import React from "react";
import useWebInstance from "grandus-lib/hooks/useWebInstance";
import {Carousel} from "antd";

const MenuCategoriesWrapper = ({children}) => {
    const carouselSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1
    };
    return (
        <>
            <>
                {children}
            </>
            <Carousel {...carouselSettings}>
                {children}
            </Carousel>
        </>
    );
}


const Menu = () => {
  const router = useRouter();
  const webInstanceData = useWebInstance()
  const { data, error } = useSWR("/api/lib/v1/categories", (url) =>
    fetch(url).then((r) => r.json())
  );

  const categories = filter(
    data,
    (category) => category?.hash !== HEADER_MENU_CATGEORY
  );

  const isCategory = startsWith(router?.route, "/kategoria/");

  const webinstance = get(webInstanceData, "webInstance", {});
  const logoPath =
      get(webinstance, "logo.path") + "/" + get(webinstance, "logo.id");
  const logo = {
    ...get(webinstance, "logo", {}),
    path: logoPath,
  };

  return (
    <nav id={styles.navigation}>
      <div className="container">
        <ul>
          <li className={styles.logoWrapper}>
              <Link href="/" as={`/`}>
                <a>
                  <Image
                      photo={logo}
                      size={"106x41"}
                      type={"png"}
                      className="logo"
                      imageProps={{
                        width: 106,
                        height: 41,
                      }}
                  />
                </a>
              </Link>
          </li>
          {isEmpty(categories) ? (
            <li style={{ height: "64px" }}>&nbsp;</li>
          ) : (
              <MenuCategoriesWrapper>
                  {
                    map(categories, (item, index) => {
              if (get(item, "hash") == CATEGORY_MICE) {
                return "";
              }
              const coverPhoto = get(item, "coverPhoto");
              return (
                <li
                  key={`navigation-item-${get(item, "id")}-${index}`}
                  className={styles.categoryCard}
                >
                  <Link
                    href="/kategoria/[category]/[[...parameters]]"
                    as={`/kategoria/${get(item, "urlName")}`}
                    scroll={true}
                  >
                    <a
                      className={`${!coverPhoto ? styles?.categoryFallback : ""}`}
                    >
                        <div className={`${styles.photo} ${styles["item-" + get(item, "hash", "default")]}`}>
                      {coverPhoto ? (
                        <Image
                          photo={coverPhoto}
                          size={"64x64__cropped"}
                          type={"png"}
                          useNextImage={true}
                          imageProps={{
                            priority: true,
                          }}
                        />
                      ) : (
                        ""
                      )}
                      {!coverPhoto && get(item, "mainPhoto") ? (
                        <Image
                          photo={get(item, "mainPhoto")}
                          size={"32x32"}
                          type={"png"}
                        />
                      ) : (
                        ""
                      )}
                        </div>
                      <span>{get(item, "name")}</span>
                    </a>
                  </Link>
                </li>
              );
            })
                  }
              </MenuCategoriesWrapper>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Menu;
